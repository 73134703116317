<script>
import { layoutComputed } from "@/state/helpers";
import LandingTemplate from "../../layouts/landingTemplate.vue";
import Hero from "@/components/Landing/hero.vue";
import GridImages from "@/components/Landing/grid-images.vue";
import Fantasy from "@/components/Landing/fantasy-section.vue";
import Subscribe from "@/components/Landing/subscribe.vue";
import ThirdPrep from "@/components/Landing/third-prep-online.vue";
import Benifits from "@/components/Landing/benifits.vue";
import FantasyGifts from "@/components/Landing/fantasy-gifts.vue";
import Navi from "@/components/Landing/navi.vue";
import Promo from "@/components/Landing/promo.vue";

/**
 * Landing layout
 */
export default {
  page: {
    title: "Dr.Micheal Labib",
  },
  components: {
    LandingTemplate,
    Hero,
    GridImages,
    Fantasy,
    Subscribe,
    ThirdPrep,
    Benifits,
    FantasyGifts,
    Navi,
    Promo,
  },
  computed: {
    ...layoutComputed,
  },
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <LandingTemplate v-if="layoutType === 'vertical'" :layout="layoutType">
       <Hero />
       <Promo />
       <Navi />
       <FantasyGifts />
       <Benifits />
       <ThirdPrep />
       <Subscribe />
       <Fantasy />
       <GridImages />
    </LandingTemplate>
    <!-- END layout-wrapper -->
  </div>
</template>


<style>

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  /* padding-top: 3rem; */
  /* padding-bottom: 3rem; */
  /* color: #fff; */
  background: none !important;
}
</style>