<script>
import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/Topbar";
import HorizontalNavbar from "@/components/Horizontal-navbar";
import Rightbar from "@/components/Rightbar";
import Footer from "@/components/Footer";
import CardFooter from '@/components/Landing/card-footer.vue';

/**
 * Horizontal layout
 */
export default {
  async created(){
    this.active = await this.$store.getters['user/isLoggedIn'];
  },
  data() {
    return {
      active: false,
      show: false,
    };
  },
  components: {
    Topbar,
    HorizontalNavbar,
    Rightbar,
    Footer,
    CardFooter,
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    this.scroll();
    document.body.setAttribute("data-layout-mode", "horizontal");
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    scroll () {
      window.onscroll = () => {
    const bottomOfWindow =
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 120;

    if (bottomOfWindow) {
      this.show = true;
      console.log('yes');
    } else {
      this.show = false;
      console.log('nope');
    }
    }
    }
  },
};
</script>

<template>
  <!-- Begin page -->
  <div id="wrapper">
    <Topbar />
    <HorizontalNavbar
      :type="'horizontal'"
      :width="'fluid'"
      :menu="'fixed'"
    />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page" >
      <div class="content" style="margin-bottom: 30rem;">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!-- content -->
      <CardFooter class="mt-5" v-if="show"/>
      <Footer/>
    </div>
    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->
    <!-- <Rightbar /> -->
  </div>
</template>
