<template>
  <main class="learning-guide mb-5">
    <nav class="student-options mb-5">
      <a href="/login">
        <b-button variant="success" style="border-radius: .8rem;width: 16rem;" size="lg"><i class="fas fa-user mr-1"></i> Existing Student</b-button>
      </a>
      <a href="/register">
        <b-button variant="warning" style="border-radius: .8rem;width: 16rem;" size="lg" ><i class="fas fa-user-plus mr-1"></i> New Student</b-button>
      </a>
    </nav>
    <header class="guide-header">
      <h1 class="guide-title">Chemistry Made Easy - Navigate Your Learning Adventure</h1>
      <p class="guide-subtitle">Your Step-by-Step Guide to Using Our Website Effectively</p>
    </header>
    <section class="guide-content">
      <!-- <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e91efd29bfa3a184db66d8f91240d25f08b6a6a5189d33ac1d12be1dcd26ea9e?apiKey=6d21be8478f942c4871a2d809fbfd5ef&&apiKey=6d21be8478f942c4871a2d809fbfd5ef" alt="Chemistry learning guide illustration" class="guide-illustration" /> -->
      <iframe lt="Chemistry learning guide illustration" class="guide-illustration" width="866" height="487" src="https://www.youtube.com/embed/z-ohHTWKynU" title="Website" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <!-- <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ea9cad549a28d0e8c064957fa3e4799ba9e1293a25b54ae2d6a300e92f2e3c7?apiKey=6d21be8478f942c4871a2d809fbfd5ef&&apiKey=6d21be8478f942c4871a2d809fbfd5ef" alt="" class="guide-decoration" /> -->
    </section>
  </main>
</template>

<script>

export default {
  name: 'ChemistryLearningGuide',
}
</script>

<style scoped>
.learning-guide {
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
}

.guide-header {
  text-align: center;
}

.guide-title {
  color: #04102D;
  font-size: 46px;
  font-weight: 700;
}

.guide-subtitle {
  color: #323A46;
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
}

.guide-content {
  position: relative;
  width: 100%;
  text-align: center;
}

.guide-illustration {
  aspect-ratio: 1.3;
  object-fit: contain;
  width: 857px;
  margin-top: 60px;
  max-width: 100%;
}

.guide-decoration {
  aspect-ratio: 1;
  object-fit: contain;
  width: 100px;
  position: absolute;
  left: 50%;
  bottom: 335px;
  transform: translateX(-50%);
  height: 100px;
}

.student-options {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 50px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .guide-title {
    font-size: 40px;
  }

  .guide-illustration {
    margin-top: 40px;
  }

  .student-options {
    margin-top: 40px;
  }
}
</style>