<template>
    <div class="row justify-content-center left-postion">
        <div class="col-12 col-md-4">
            <section class="rounded-container text-center">
                <h1 class="get-started-button mt-3 mb-1">Get Started Now!</h1>
                <a href="/register">
                  <b-button pill variant="success" style="width: 200px; margin: auto;" >Sign Up</b-button>
                </a>
            </section>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RoundedContainer'
  }
  </script>
  
  <style scoped>
  .get-started-button {
    color: #04102d;
    max-width: 216px;
    font: 900 28px Lato, sans-serif;
    background: none;
    border: none;
    cursor: pointer;
    margin: auto;
    }
  .rounded-container {
    border-radius: 12px;
    background-color: #fff;
    min-height: 193px;
    max-width: 460px;
    border: 1px solid #1abc9c;
  }
  @media (min-width: 461px) {
    .left-postion{
        left: 25%;
        position: fixed;
        bottom: 280px;
        z-index: 1000;
    }
    .rounded-container{
        width: 460px;
    }
  }
  @media (max-width: 460px) { 
    .left-postion{
        left: 15%;
        position: fixed;
        bottom: 290px;
        z-index: 1000;
    }
    .rounded-container{
        width: 280px;
    }
  }
  </style>